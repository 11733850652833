<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Course </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Course
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createCourse"
                      class="mt-1 btn btn-block btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Course
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <form @submit.prevent="searchCourses" class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Course Title or Subject Code"
                      v-model="search.title"
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Status"
                      v-model="search.status"
                      outlined
                      item-text="name"
                      item-value="value"
                      :items="status"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      type="submit"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                    @click="resetSearch"
                      class="mt-1 btn btn-block btn-secondary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-power-off</v-icon>&nbsp;
                    Reset
                  </v-btn>
                </v-col>
              </form>

            </div>

            <div class="table-responsive">
              <table class="table"
                     id="kt_advance_table_widget_4">
                <thead>
                <tr class="text-left">
                  <th class="">Title</th>
                  <th class="">Subject Code</th>
                  <th class="">Image</th>
                  <th class="">State Price</th>
                  <th class="">Rising Software Key</th>
                  <th class="">Position</th>
                  <th class="">Availability</th>
                  <th class="">Visibility</th>
                  <th class="pr-3 ">Status</th>
                  <th class="pr-3 ">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in courses">
                    <td>
                      <a class="font-weight-bolder text-primary mb-1 font-size-lg">
                        {{item.title}}
                      </a>
                    </td>
                    <td class="">
                      <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{ item.subject_code }}
                      </span>
                    </td>
                    <td class="">
                      <a class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        <v-img
                            v-if="item.image != null"
                            width="60px"
                            height="60px"
                            style="object-fit: cover;"
                            :lazy-src="item.image_path"
                            class="img-thumbnail" alt="">
                        </v-img>
                        <span v-else>
                          NA
                        </span>
                      </a>
                    </td>
                    <td class="">
                      <span class="text-dark-75 mb-1 font-size-lg">
                        {{ item.state_price }}
                      </span>
                    </td>
                    <td class="">
                      <span v-if="item.rising_software_key" class="text-dark-75 mb-1 font-size-lg">
                        {{item.rising_software_key}}
                      </span>
                      <span v-else>
                        NA
                      </span>
                    </td>
                    <td class="">
                      <span v-if="item.position" class="text-dark-75 mb-1 font-size-lg">
                        {{item.position}}
                      </span>
                      <span v-else>
                        NA
                      </span>
                    </td>
                    <td class="">
                      <span
                          v-if="item.availiability_state == 'available'"
                          class="text-green mb-1 font-size-lg">
                        <i class="fa fa-check-alt text-green"></i>
                        Available
                      </span>
                      <span
                          v-if="item.availiability_state == 'not_available'"
                          class="text-danger mb-1 font-size-lg">
                        <i class="fa fa-window-close text-danger"></i>
                        Not Available
                      </span>
                    </td>
                    <td class="">
                      <span
                          v-if="item.visibility == 'visible'"
                          class="text-green mb-1 font-size-lg">
                        <i class="fa fa-eye text-green"></i>
                        Visible
                      </span>
                      <span
                          v-if="item.visibility == 'not_visible'"
                          class="text-danger mb-1 font-size-lg">
                        <i class="fa fa-window-close text-danger"></i>
                        Not Visible
                      </span>
                    </td>
                    <td class="">
                      <span
                          class="badge"
                          :class="item.status == 'active' ? 'badge-success' : 'badge-danger'"
                      >
                        {{ item.status == 'active' ? 'Active' : 'Inactive' }}
                      </span>
                    </td>
                    <td class="pr-0 ">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editCourse(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="deleteCourse(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon>fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon>fas fa-plus</v-icon>
                                </span>
                                <span class="navi-text">Manage Lessons</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <b-pagination
                v-if="courses.length > 0"
                class="pull-right mt-7"
                @input="getAllCourses"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>
<script>
import CourseService from "@/services/product/course/CourseService";

const course = new CourseService();
export default {
  data() {
    return{
      total: null,
      perPage: null,
      page: null,
      courses:[],
      search:{
        title:'',
        status:'',
      },
      status: [
          { name: 'Active', value: 'active' },
          { name: 'Inactive', value: 'in_active'}
      ],
    }
  },
  methods: {
    createCourse(){
      this.$router.push({
        name: "course-create",
      });
    },
    editCourse(courseId){
      this.$router.push({
        name:"course-edit",
        params: {courseId: courseId}
      });
    },
    resetSearch(){
      this.search = {};
      this.getAllCourses();
    },
    getAllCourses(){
      course
          .paginate(this.search, this.page)
          .then(response => {
            this.courses=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          });
    },
    searchCourses(){
      this.getAllCourses();
    },
    deleteCourse(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            course
              .delete(item.id)
              .then((response) => {
                this.getAllCourses()
                this.$snotify.success("Sucessfully Deleted");
              })
              .catch((err) => {
                this.$snotify.error("Oops something went wrong");
              });
            }
        }
      });
    }
  },
  mounted() {
    this.getAllCourses();
  }
}
</script>


<style scoped>
.text-green{
  color: #059505;
}
</style>